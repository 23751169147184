import styled from 'styled-components'; 

export const HomeContainer = styled.div`
<<<<<<< HEAD
=======
  width: 100%;
  background: #F5F5F5;
  display: flex;
  justify-content: center;
  align-items: center;


body, h1, ul {
  margin: 0;
  padding: 0;
}

>>>>>>> develop
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

`;